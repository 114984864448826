module.exports = [{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/vercel/path0/node_modules/@philpl/gatsby-theme-mdx-deck/src/templates/deck.js"},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-import-code"],"remarkPlugins":[null,null]},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/node_modules/@philpl/gatsby-theme-mdx-deck/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
