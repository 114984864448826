import { CodeSurfer as Surfer } from "code-surfer";
import { Appear, Background } from "gatsby-theme-mdx-deck";
import Cereal from "../../../../assets/cereal.svg";
import customTheme from "../../../../src/theme";
import * as L from "../../../../src/layout";
import FormidableIntro from "../../../../assets/logo.svg";
import DevProcessImg from "../../../../assets/dev-proses.jpeg";
import PetaBakat from "../../../../assets/peta-bakat.png";
import NotTools from "../../../../assets/not-tools.jpeg";
import ProgressImg from "../../../../assets/perfection-vs-progress.jpeg";
import React from 'react';
export default {
  Surfer,
  Appear,
  Background,
  Cereal,
  customTheme,
  L,
  FormidableIntro,
  DevProcessImg,
  PetaBakat,
  NotTools,
  ProgressImg,
  React
};